<template>
  <div class="page-profile">
    <!-- Navbar -->
    <Navbar />

    <!-- Sidebar -->
    <Sidebar />

    <!-- Main Profile -->
    <section id="profile" class="profile">
      <div class="container position-relative">
        <aside class="vertical-bar d-none d-lg-block">
          <a
            href="https://smkn4bdg.sch.id/"
            rel="noopener"
            target="_blank"
            class="vertical-text text-decoration-none"
            >SMKN 4 BANDUNG</a
          >
        </aside>

        <div
          ref="profilesSwiper"
          v-swiper:profilesSwiper="swiperProfilesOptions"
          @transitionEnd="profileChanged"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              :key="`profile-${i}`"
              v-for="(profile, i) in profiles"
            >
              <div class="d-flex flex-column align-items-center h-100">
                <div class="img">
                  <img :src="profile.img" :alt="profile.name" class="w-100" />
                  <svg
                    width="1299"
                    height="834"
                    viewBox="0 0 1299 834"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="1299" height="834" fill="#101010" />
                  </svg>
                </div>

                <div class="name text-center">
                  <h2 class="text-uppercase">{{ profile.alias }}</h2>
                  <h5 class="text-uppercase">{{ profile.job }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="dots">
          <div
            v-for="(profile, i) in profiles"
            :key="`profile-dot-${i}`"
            @click="changeProfile(i)"
            class="dot"
            :class="[activeProfile == i ? 'active' : '']"
          >
            <div class="circle"></div>
          </div>
        </div>
      </div>

      <Parallax en="PROFILE" id="PROFIL" title="PROFILE" />
    </section>

    <!-- Footer -->
    <Footer isSmall />

    <!-- Toggle Mobile -->
    <ToggleMobile />
  </div>
</template>

<script>
// Library
import { directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

// Layouts
import Navbar from "@/components/layouts/Navbar.vue";
import Sidebar from "@/components/layouts/Sidebar.vue";
import Footer from "@/components/layouts/Footer.vue";

// Components
import Parallax from "@/components/components/Parallax.vue";
import ToggleMobile from "@/components/components/ToggleMobile.vue";

// Services
import initParallax from "@/services/parallax/InitParallax.js";

export default {
  name: "Profile",
  metaInfo: {
    title: "Profile - Space Walk",
    description:
      "Space Walk is a website that is intended for people who have interests and talents in astronomy, with some basic knowledge, it is hoped that many people will start to be interested in astronomy.",
  },
  components: {
    Navbar,
    Sidebar,
    Footer,
    Parallax,
    ToggleMobile,
  },
  directives: {
    swiper: directive,
  },

  data() {
    return {
      swiperProfilesOptions: {
        // loop: true,
        grabCursor: true,
        speed: 500,
        slidesPerView: 1,
        direction: "vertical",
      },
      profiles: [
        {
          name: "Ariq Hikari Hidayat",
          alias: "Ariq",
          job: "Front-End Developer",
          img: "/assets/img/profile/ariq.png",
        },
        {
          name: "Muhamad Bilal Arief Suryanto",
          alias: "Bilal",
          job: "UI/UX Designer",
          img: "/assets/img/profile/bilal.png",
        },
        {
          name: "Rachel Sabardila",
          alias: "Rachel",
          job: "Back-End Developer",
          img: "/assets/img/profile/rachel.png",
        },
      ],
      activeProfile: 0,
    };
  },
  mounted() {
    initParallax();
    this.setName();
  },
  methods: {
    changeProfile(i) {
      const swiper = this.$refs.profilesSwiper.swiper;
      swiper.slideTo(i);

      this.activeProfile = i;
    },
    profileChanged(swiper) {
      this.activeProfile = swiper.realIndex;

      const sliders = document.querySelectorAll(".swiper-slide .name");
      sliders.forEach((slider) => {
        slider.classList.remove("active");
      });

      this.setName();
    },
    setName() {
      document
        .querySelector(".swiper-slide-active .name")
        .classList.add("active");
    },
  },
};
</script>